// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"
import Sidebar from "../layouts/sidebar"

import Bio from "../components/bio"
import Layout from "../layouts/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

import BigAvatar from "../components/big-avatar"

const HomePage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <div>
        <BigAvatar></BigAvatar>
        <div>Hi. I'm Ethan.</div>
      </div>
    </Layout>
  )
}

export default HomePage

// type Data = {
//   site: {
//     siteMetadata: {
//       title: string
//     }
//   }
//   allMarkdownRemark: {
//     edges: {
//       node: {
//         excerpt: string
//         frontmatter: {
//           title: string
//           date: string
//           description: string
//           type: string
//         }
//         fields: {
//           slug: string
//         }
//       }
//     }[]
//   }
// }
//
// const BlogIndex = ({ data, location }: PageProps<Data>) => {
//   const siteTitle = data.site.siteMetadata.title
//   const posts = data.allMarkdownRemark.edges
//
//   return (
//     <Layout location={location} title={siteTitle}>
//       <SEO title="All posts" />
//       <Bio />
//       {posts.map(({ node }) => {
//         const title = node.frontmatter.title || node.fields.slug
//         return (
//           <article key={node.fields.slug}>
//             <header>
//               <h3
//                 style={{
//                   marginBottom: rhythm(1 / 4),
//                 }}
//               >
//                 <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
//                   {title}
//                 </Link>
//               </h3>
//               <small>{node.frontmatter.date}</small>
//             </header>
//             <section>
//               <p
//                 dangerouslySetInnerHTML={{
//                   __html: node.frontmatter.description || node.excerpt,
//                 }}
//               />
//             </section>
//           </article>
//         )
//       })}
//     </Layout>
//   )
// }
//
// export default BlogIndex
//
// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       edges {
//         node {
//           excerpt
//           fields {
//             slug
//           }
//           frontmatter {
//             date(formatString: "MMMM DD, YYYY")
//             title
//             description
//           }
//         }
//       }
//     }
//   }
// `
